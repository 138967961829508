import moment from 'moment';
import { useEffect, useState } from 'react';

import { cn } from '~/utils/css';
import { getDeliveryDate } from '~/utils/order/dates';

function formatUnit(value: number, singular: string, plural: string) {
  return `${value} ${value === 1 ? singular : plural}`;
}

function format(count: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) {
  const days = count.days > 0 ? formatUnit(count.days, 'day', 'days') : '';
  const hours =
    (count.days > 0 && count.hours > 0) || count.hours > 0
      ? formatUnit(count.hours, 'hour', 'hours')
      : '';

  const minutes = formatUnit(count.minutes, 'min', 'mins');
  const seconds = formatUnit(count.seconds, 'sec', 'secs');

  // Create a string with all of the units that have a value. This allows
  // us to omit sections in the final string without needed to do any
  // weird concatinations.
  const str = [days, hours, minutes, seconds].reduce((final, curr) => {
    if (curr) {
      return final + curr + ', ';
    }

    return final;
  }, '');

  // -2 is to find the length based on a zero index and to remove the last
  // character in the string.
  return str.slice(0, str.length - 2);
}

function getCounts(currentDate: Date, cutOffDate: Date) {
  const distance = cutOffDate.getTime() - currentDate.getTime();

  const counts = {
    days: Math.floor(distance / (1000 * 60 * 60 * 24)),
    hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((distance % (1000 * 60)) / 1000),
  };

  return counts;
}

function formatToDateMonth(date: Date) {
  return date.toLocaleDateString().slice(0, -5);
}

type DeliveryDates = { monday: string; sunday: string };

export function CutoffCountdown({ date = '' }: { date: string }) {
  const [countdown, setCountdown] = useState('');
  const [deliveryDate, setDeliveryDate] = useState<DeliveryDates>({
    monday: '',
    sunday: '',
  });

  useEffect(() => {
    // Make sure we don't do anything when there is no date set.
    if (date === '') return;

    const cutoff = new Date(date);

    // We want to make sure we only update the time every second (or 1000ms)
    // since that's what we show.
    const i = setInterval(() => {
      const currentDate = new Date();
      const counts = getCounts(currentDate, cutoff);

      setCountdown(format(counts));
    }, 1000);

    // Clear out the interval once the component is removed. If we don't,
    // we'll be running into a lot of memory leaks.
    return () => clearInterval(i);
  }, [date, countdown]);

  useEffect(() => {
    const orderConfirmedDate = moment();

    setDeliveryDate({
      monday: formatToDateMonth(
        getDeliveryDate({
          deliveryDay: 'Monday',
          orderConfirmedDate,
        }).toDate()
      ),
      sunday: formatToDateMonth(
        getDeliveryDate({
          deliveryDay: 'Sunday',
          orderConfirmedDate,
        }).toDate()
      ),
    });
  }, []);

  const isCountdownLoaded = countdown !== '';

  return (
    <div className='border-b bg-zinc-50 dark:border-zinc-700 dark:bg-zinc-900'>
      <div className='mx-auto flex h-auto max-w-7xl items-center justify-center px-4 py-2 sm:px-6 lg:px-8'>
        <div className='relative w-full text-center'>
          {/* 
            Show a skeleton loader so then we know that the cut off 
            date is loading 
          */}
          <div className='pointer-events-none absolute bottom-0 top-0 flex w-full items-center justify-center'>
            <div
              className={cn(
                'mx-4 h-full w-full max-w-3xl select-none rounded-lg bg-zinc-200 transition-opacity dark:bg-zinc-600',
                !isCountdownLoaded
                  ? 'pointer-events-none animate-pulse opacity-100'
                  : 'opacity-0'
              )}
            />
          </div>

          <p
            className={cn(
              'z-10 text-xs font-medium text-zinc-500 transition-opacity duration-700 dark:text-zinc-300 xsm:text-sm sm:mx-auto sm:max-w-lg md:max-w-xl lg:max-w-none',
              isCountdownLoaded ? 'opacity-100' : 'opacity-0'
            )}>
            <span className='md:hidden'>
              Order in{' '}
              <b className='normal-nums text-zinc-700 dark:text-zinc-100'>
                {countdown}
              </b>{' '}
              to receive your order by Sunday ({deliveryDate.sunday}) or Monday
              ({deliveryDate.monday})
            </span>

            <span className='hidden md:inline'>
              Order in the next{' '}
              <b className='normal-nums text-zinc-700 dark:text-zinc-100'>
                {countdown}
              </b>{' '}
              to receive your order by Sunday ({deliveryDate.sunday}) or Monday
              ({deliveryDate.monday})
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
