import { Link } from '@remix-run/react';

import { ShoppingBasket } from 'lucide-react';

import { routes } from '~/utils/routes';

type Props = {
  itemsInCart: number;
};

export function CartButton(props: Props) {
  return (
    <div className='-mr-2 ml-2 flex w-auto max-w-12 flex-1 flex-shrink justify-end bg-white md:max-w-none md:flex-none'>
      <Link
        to={routes.cart.index}
        className='inline-flex items-center justify-center rounded-md p-2 text-sm font-semibold leading-6 text-gray-500 hover:bg-gray-100 hover:text-gray-600 focus:outline-none lg:pr-3'>
        <span className='sr-only'>Go to cart</span>

        <ShoppingBasket className='h-6 w-6' aria-hidden='true' />

        <span className='ml-2 font-medium'>{props.itemsInCart ?? 0}</span>
      </Link>
    </div>
  );
}
