import { PopoverGroup } from '@headlessui/react';

import { desktopLinks } from '../../constants';
import { DesktopDropdownLink } from '../dropdown/desktop';
import { DesktopTopLink } from '../link';

export function DesktopNavigation() {
  return (
    <PopoverGroup className='relative z-10 hidden md:flex md:flex-1 md:flex-shrink-0 md:justify-center md:gap-x-4 lg:gap-x-10'>
      {desktopLinks.map((item) =>
        item.dropdown ? (
          <DesktopDropdownLink key={item.href} link={item} />
        ) : (
          <DesktopTopLink key={item.href} {...item} />
        )
      )}
    </PopoverGroup>
  );
}
