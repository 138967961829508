import { Link } from '@remix-run/react';

import { routes } from '~/utils/routes';

export function Logo() {
  return (
    <div className='flex flex-1 justify-center md:flex-none md:flex-shrink md:justify-start'>
      <Link to={routes.index} className='-m-1.5 p-1.5'>
        <span className='sr-only'>Shape Up Meal Prep</span>

        <img
          className='h-12 w-auto md:h-12'
          src='/images/sump-logo.svg'
          alt='Shape Up Meal Prep Logo'
        />
      </Link>
    </div>
  );
}
