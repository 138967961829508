import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Link } from '@remix-run/react';

import { ChevronDown } from 'lucide-react';

import type { BannerLink } from '~/services/user.server';
import { cn } from '~/utils/css';
import { routes } from '~/utils/routes';

function NotSignedInBanner() {
  return (
    <div className='mx-auto flex min-h-10 max-w-7xl items-center justify-between px-4 sm:px-6'>
      <div></div>

      <div className='flex items-center space-x-6'>
        <Link
          to={routes.auth.login}
          className='text-sm font-medium text-white hover:text-gray-100'>
          Sign in
        </Link>

        <Link
          to={routes.auth.register}
          className='text-sm font-medium text-white hover:text-gray-100'>
          Create an account
        </Link>
      </div>
    </div>
  );
}

type SignedInLinkProps = {
  name: string;
  href: string;
};

function SignedInLink(props: SignedInLinkProps) {
  return (
    <MenuItem>
      {({ close }) => (
        <Link to={props.href} onClick={close} className='block'>
          <div className='w-full rounded-md border-2 border-zinc-200/30 px-4 py-2'>
            <p>{props.name}</p>
          </div>
        </Link>
      )}
    </MenuItem>
  );
}

type SignedInBannerProps = {
  firstName: string;
  email: string;
  links: BannerLink[];
};

function SignedInBanner(props: SignedInBannerProps) {
  return (
    <Menu>
      <div className='mx-auto flex min-h-10 max-w-7xl items-center justify-between px-4 sm:px-6'>
        <div className='flex flex-col items-start py-2'>
          <span className='text-sm'>Hi, {props.firstName}!</span>
        </div>

        <MenuButton className='-mx-2 my-1 rounded-md px-3 py-2 text-xs font-medium text-white hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm'>
          {({ open }) => (
            <span className='flex cursor-pointer select-none items-center'>
              My Account
              <ChevronDown
                className={cn(
                  'ml-2 h-3 w-3 transform transition sm:h-3.5 sm:w-3.5',
                  open ? 'rotate-180' : ''
                )}
                aria-hidden='true'
              />
            </span>
          )}
        </MenuButton>
      </div>

      <MenuItems className='mx-auto flex max-w-7xl flex-col space-y-3 px-4 pb-4 sm:px-6'>
        <div className='flex items-center justify-between border-t border-t-gray-700 pt-2'>
          <div>
            <p className='text-sm font-normal text-gray-400'>{props.email}</p>
          </div>

          <div>
            <form method='post' action={routes.auth.logout}>
              <button type='submit' className='text-sm'>
                Sign out
              </button>
            </form>
          </div>
        </div>

        <div className='grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2 md:grid-cols-3'>
          {props.links.map((item) => (
            <SignedInLink key={item.href} name={item.name} href={item.href} />
          ))}
        </div>
      </MenuItems>
    </Menu>
  );
}

type AccountBannerProps = {
  user: { email: string; firstName: string } | null;
  links: BannerLink[];
};

export function AccountBanner(props: AccountBannerProps) {
  return (
    <div className='bg-gray-900 text-sm font-medium text-white'>
      {props.user ? (
        <SignedInBanner
          firstName={props.user.firstName}
          email={props.user.email}
          links={props.links ?? []}
        />
      ) : (
        <NotSignedInBanner />
      )}
    </div>
  );
}
