import { Popover } from '@headlessui/react';

import { CartButton } from './components/cart.button';
import { Logo } from './components/logo';
import { DesktopNavigation } from './components/navigation/desktop';
import { MobileNavigation } from './components/navigation/mobile';
import { Toggle } from './components/toggle';

type Props = {
  itemsInCart: number;
};

export function Navigation(props: Props) {
  return (
    <Popover as='header' className='relative border-b bg-white'>
      <nav
        className='mx-auto flex max-w-7xl items-center justify-between px-6 py-4 lg:px-8 lg:py-6'
        aria-label='Global'>
        {/* This will only be shown on mobile devices */}
        <Toggle />

        {/* Centered on mobile; left aligned on desktop */}
        <Logo />

        <DesktopNavigation />

        {/* Cart button */}
        <CartButton itemsInCart={props.itemsInCart} />
      </nav>

      <MobileNavigation itemsInCart={props.itemsInCart} />
    </Popover>
  );
}
